@import './question.scss';
@import './scale.scss';
@import './section.scss';

.questionnaire {
  &__actions {
    display: flex;
    position: absolute;
    right: 0;
    bottom: -60px;

    button {
      margin-left: 15px;
    }

    a {
      padding: 0 15px;
      width: initial;
    }
  }
}
